import React, { useRef, useEffect, useState, useCallback } from "react"
import { addPropertyControls, ControlType } from "framer"

export function VturbPlayer({ vturbCode, secondsToDisplay, classToDisplay }) {
    const containerRef = useRef(null)
    const [elementsShown, setElementsShown] = useState(false)

    // Função para obter a URL base sem parâmetros
    const getBaseUrl = useCallback(() => {
        const url = new URL(window.location.href)
        return `${url.origin}${url.pathname}`
    }, [])

    const initializePlayer = useCallback(() => {
        const container = containerRef.current

        // Limpa o contêiner antes de adicionar um novo player
        while (container.firstChild) {
            container.removeChild(container.firstChild)
        }

        // Cria um novo elemento div para conter o código do player
        const playerContainer = document.createElement("div")
        playerContainer.innerHTML = vturbCode

        // Adiciona o código do player ao contêiner
        container.appendChild(playerContainer)

        // Obtém a tag script do código do player
        const scriptTag = playerContainer.querySelector("script")

        // Executa o código dentro da tag script
        try {
            eval(scriptTag.innerHTML)
        } catch (error) {
            console.error("Erro ao inicializar o player:", error)
        }
    }, [vturbCode])

    const showEls = useCallback(() => {
        const baseUrl = getBaseUrl()
        const storageKey = `alreadyElsDisplayed_${baseUrl}`

        const elements = document.querySelectorAll(classToDisplay)
        elements.forEach((element) => {
            element.classList.remove("esconder")
            element.style.removeProperty("display")
        })
        localStorage.setItem(storageKey, "true")
    }, [classToDisplay, getBaseUrl])

    const startWatchVideoProgress = useCallback(() => {
        let attempts = 0
        const checkPlayer = () => {
            if (
                typeof smartplayer === "undefined" ||
                !(smartplayer.instances && smartplayer.instances.length)
            ) {
                attempts += 1
                if (attempts >= 10) return
                return setTimeout(checkPlayer, 1000)
            }

            smartplayer.instances[0].on("timeupdate", () => {
                if (
                    smartplayer.instances[0].video.currentTime >=
                    secondsToDisplay
                ) {
                    setElementsShown(true)
                }
            })
        }
        checkPlayer()
    }, [secondsToDisplay])

    useEffect(() => {
        const baseUrl = getBaseUrl()
        const storageKey = `alreadyElsDisplayed_${baseUrl}`

        initializePlayer()

        // Verifica se os elementos já foram exibidos anteriormente
        const alreadyElsDisplayed = localStorage.getItem(storageKey)

        if (alreadyElsDisplayed === "true") {
            setElementsShown(true)
        } else {
            startWatchVideoProgress()
        }

        const handleResize = () => {
            if (
                typeof smartplayer !== "undefined" &&
                smartplayer.instances &&
                smartplayer.instances.length
            ) {
                const currentTime = smartplayer.instances[0].video.currentTime
                if (currentTime >= secondsToDisplay) {
                    setElementsShown(true)
                }
            }
        }

        window.addEventListener("resize", handleResize)
        window.addEventListener("orientationchange", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
            window.removeEventListener("orientationchange", handleResize)
        }
    }, [
        vturbCode,
        secondsToDisplay,
        classToDisplay,
        getBaseUrl,
        initializePlayer,
        startWatchVideoProgress,
    ])

    useEffect(() => {
        if (elementsShown) {
            showEls()
        }
    }, [elementsShown, showEls])

    return <div ref={containerRef} />
}

// ... (o resto do código permanece o mesmo)

VturbPlayer.defaultProps = {
    vturbCode: `
    <div id="vid_66577d0d150fc9000b75519d" style="position:relative;width:100%;padding: 177.77777777777777% 0 0;">
      <img id="thumb_66577d0d150fc9000b75519d" src="https://images.converteai.net/693742f3-b2a9-47a8-90be-b9911b88f4b5/players/66577d0d150fc9000b75519d/thumbnail.jpg" style="position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;display:block;">
      <div id="backdrop_66577d0d150fc9000b75519d" style="position:absolute;top:0;width:100%;height:100%;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);"></div>
    </div>
    <script type="text/javascript" id="scr_66577d0d150fc9000b75519d">
      var s=document.createElement("script");
      s.src="https://scripts.converteai.net/693742f3-b2a9-47a8-90be-b9911b88f4b5/players/66577d0d150fc9000b75519d/player.js",
      s.async=!0,document.head.appendChild(s);
    </script>
  `,
    secondsToDisplay: 5,
    classToDisplay: ".esconder",
}

addPropertyControls(VturbPlayer, {
    vturbCode: {
        type: ControlType.String,
        title: "Código do Player do Vturb",
        defaultValue: VturbPlayer.defaultProps.vturbCode,
        displayTextArea: true,
    },
    secondsToDisplay: {
        type: ControlType.Number,
        title: "Segundos para Exibir",
        defaultValue: VturbPlayer.defaultProps.secondsToDisplay,
        min: 0,
        step: 1,
    },
    classToDisplay: {
        type: ControlType.String,
        title: "Classe dos Elementos a Exibir",
        defaultValue: VturbPlayer.defaultProps.classToDisplay,
    },
})
